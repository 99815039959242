import {
  SET_TOOL_CONFIG,
  MOUNT_LAYER_SETTINGS,
  MOUNT_MAP_VIEWS,
  INCREMENT_MOUNT_VIEWS,
  SET_MOUNT_MAPVIEWS,
  OPEN_NOTES,
} from '../actions/types'

export default function ToolReducer(state = null, action) {
  switch (action.type) {
    case SET_TOOL_CONFIG: {
      return action.payload
    }
    default:
      return state
  }
}

export function MountLayerSettings(state = 1, action) {
  switch (action.type) {
    case MOUNT_LAYER_SETTINGS: {
      return action.payload
    }
    default:
      return state
  }
}

export function MountMapViews(state = 1, action) {
  switch (action.type) {
    case MOUNT_MAP_VIEWS: {
      return action.payload
    }
    case INCREMENT_MOUNT_VIEWS: {
      return state + 1
    }
    case SET_MOUNT_MAPVIEWS: {
      return action.payload
    }
    default:
      return state
  }
}

export function OpenNotes(state = null, action) {
  switch (action.type) {
    case OPEN_NOTES: {
      return action.payload
    }
    default:
      return state
  }
}
