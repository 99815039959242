export default {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'my-asset-map-data',
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://ye8wu3frvb.execute-api.us-east-1.amazonaws.com/dev/',
    URL2: 'https://m08fxeonwk.execute-api.us-east-1.amazonaws.com/dev',
  },
  apiDatabase: {
    REGION: 'us-east-1',
    URL: 'https://rg91b1juf3.execute-api.us-east-1.amazonaws.com/dev',
    HEADERS: {
      DEV: {
        'Auth-DEV': 'DEV-3f69e80b-63d0-47b0-8ed6-a503f2245619',
      },
      PROD: {
        'Auth-PROD': 'PROD-3f69e80b-63d0-47b0-8ed6-a503f2245619',
      },
    },
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_KQ2QyG6S3',
    APP_CLIENT_ID: '10oct0ch5qbh22e4vc1gc0r7sl',
    IDENTITY_POOL_ID: 'us-east-1:12bea15a-75c6-4630-801c-9bcb9937ef43',
  },
  s3Config: {
    region: 'us-east-1',
    bucketName: 'my-asset-map-data',
    signedUrlExpireSeconds: 86400,
  },
  s3Auth: {
    accessKeyId: 'AKIA3SSZS6TS2TWGW4FK',
    secretAccessKey: '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',
    region: 'us-east-1',
    signatureVersion: 'v4',
  },
  clarity: {
    projectId: 'j4jzb8129l',
  },
  gtm: {
    id: 'GTM-WQNXNHRQ',
  },
  logrocket: {
    id: 'myassetmap/myassetmap',
  },
}
