import { Auth, API } from 'aws-amplify'

// DO NOT USE

// @TODO More of a proof of concept but we'll need to do a lot of work refactoring the authentication flow to use this

type ApiResponse = {
  success: boolean
  message?: string
  data?: unknown
  error?: Error
  userUUID?: string
}

type QueryFetchOptions = {
  url: string
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  body?: Record<string, unknown> | null
}

const apiFetch = async (
  url: string,
  method = 'GET',
  body: Record<string, unknown> | null = null
): Promise<ApiResponse> => {
  try {
    const session = await Auth.currentSession()
    const cogID =
      localStorage.getItem('userImpersonation') ??
      session.getIdToken().payload.sub
    const userUrl = new URL(url)
    userUrl.searchParams.set('userID', cogID)

    const path = userUrl.pathname + userUrl.search
    const init = {
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    // Use the appropriate API method based on the HTTP method
    let response
    switch (method) {
      case 'POST':
        response = await API.post('MyAssetMapAPI', path, init)
        break
      case 'PUT':
        response = await API.put('MyAssetMapAPI', path, init)
        break
      case 'DELETE':
        response = await API.del('MyAssetMapAPI', path, init)
        break
      default:
        response = await API.get('MyAssetMapAPI', path, init)
    }

    return { ...response, userUUID: cogID }
  } catch (error) {
    return {
      success: false,
      message: error instanceof Error ? error.message : 'Unknown error',
      error: error instanceof Error ? error : undefined,
    }
  }
}

/**
 * Query-friendly version of apiFetch specifically for use with React Query
 */
export const queryFetch = ({
  url,
  method = 'GET',
  body = null,
}: QueryFetchOptions) => {
  return apiFetch(url, method, body)
}
