import { useEffect } from 'react'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'
import { clarity } from 'react-microsoft-clarity'
import environment from '_environments'

export const useAnalytics = user => {
  useEffect(() => {
    // Skip analytics in development environments
    const isLocalEnv =
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'

    if (isLocalEnv || environment.isDev) {
      return undefined
    }

    const userInfo = user?.UUID
      ? {
          name: user.profile.displayName,
          email: user.profile.emailAddress,
        }
      : null

    const tags = user?.UUID
      ? {
          accountID: user.accountID,
          mapID: user.mapID,
        }
      : null

    // Initialize LogRocket
    if (user?.UUID) {
      try {
        LogRocket.identify(user.UUID, {
          ...userInfo,
          ...tags,
        })
      } catch (error) {
        console.error('Failed to initialize LogRocket:', error)
      }
    }

    // Initialize Sentry
    if (user?.UUID) {
      try {
        Sentry.setUser(userInfo)
        Object.entries(tags).forEach(([key, value]) => {
          Sentry.setTag(key, value)
        })
      } catch (error) {
        console.error('Failed to initialize Sentry:', error)
      }
    }

    // Initialize Clarity
    try {
      if (user?.UUID) {
        clarity.identify(user.UUID)
        clarity.setTag('env', environment.env)
        clarity.setTag('name', user.profile.displayName)
        clarity.setTag('email', user.profile.emailAddress)
        clarity.setTag('accountID', user.accountID)
        clarity.setTag('mapID', user.mapID)
      }
    } catch (error) {
      console.error('Failed to initialize Clarity:', error)
    }

    // Cleanup function
    const cleanup = function cleanup() {
      try {
        // Cleanup Sentry
        Sentry.setUser(null)
        Object.keys(tags || {}).forEach(key => {
          Sentry.setTag(key, null)
        })

        // Cleanup Clarity
        if (window.clarity) {
          window.clarity('clearIdentity')
          window.clarity('clearTags')
        }

        // Attempt LogRocket cleanup
        if (
          window.LogRocket &&
          typeof window.LogRocket.unsubscribe === 'function'
        ) {
          window.LogRocket.unsubscribe()
        }
      } catch (error) {
        console.error('Failed to cleanup analytics:', error)
      }
    }

    return cleanup
  }, [user])
}
