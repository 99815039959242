import { QueryClient } from '@tanstack/react-query'
import { UserData, UserInitResponse } from '@/types/user'
import nearmapUsageTracker from './nearmapUsageTracker'
import { verifyResult } from '@/utilities/utils'

// Create a singleton instance
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      retry: 2,
    },
  },
})

const verifyUserData = (
  data: UserInitResponse
): data is UserInitResponse & { data: UserData } => {
  return verifyResult(data) && data.success && !!data.data
}

const defaultUser = (): UserData => ({
  UUID: '',
  isSuperAdmin: false,
  isAdmin: false,
  isActive: false,
  role: undefined,
  permissions: undefined,
  adminAccounts: [],
  profile: undefined,
  accounts: [],
  preferences: undefined,
  accountID: 0,
  mapID: 0,
  isLoggedIn: false,
})

// Encapsulated API for query client operations
export const queryClientManager = {
  // User data operations
  getUserData: () => {
    const rawData = queryClient.getQueryData<UserInitResponse>(['user', 'init'])
    if (!rawData) return null

    if (verifyUserData(rawData)) {
      const {
        profile,
        isSuperAdmin,
        isAdmin,
        isActive,
        role,
        permissions,
        adminAccounts,
        accounts = [],
        preferences,
        accountID,
        mapID,
      } = rawData.data

      const user = defaultUser()
      user.UUID = rawData.userUUID ?? ''

      // Handle account selection and validation
      if (!accounts.length) {
        throw new Error(
          'You are not a part of any accounts. Please contact support@myassetmap.com.'
        )
      }

      // Validate first account has an ID if we need to use it as fallback
      const firstValidAccount = accounts.find(acc => acc.id != null)
      if (!firstValidAccount) {
        throw new Error(
          'Invalid account data. Please contact support@myassetmap.com.'
        )
      }

      // Set accountID with fallback to first valid account if none specified
      user.accountID = accountID ?? firstValidAccount.id
      const account = accounts.find(acc => acc.id === user.accountID)

      if (!account) {
        throw new Error(
          'No active accounts have been found. Please have your admin add to you an account.'
        )
      }

      // Set user permissions and role based on account
      user.isAdmin = isAdmin || adminAccounts?.includes(user.accountID) || false
      user.isActive = isActive
      user.role = role
      user.permissions = permissions
      user.adminAccounts = adminAccounts
      user.accounts = accounts

      // Handle map selection with fallbacks
      user.mapID = mapID ?? account.maps?.[0]?.id ?? null

      // Set remaining user data
      user.profile = profile
      user.preferences = preferences
      user.isSuperAdmin = isSuperAdmin

      // Validate required data exists
      if (!user.UUID || !user.accountID || !user.mapID) {
        throw new Error(
          'Missing required user data. Please contact support@myassetmap.com.'
        )
      }

      user.isLoggedIn = true
      return user
    }
    return defaultUser()
  },

  setUserData: (data: UserInitResponse) =>
    queryClient.setQueryData(['user', 'init'], data),

  invalidateUserQueries: () =>
    queryClient.invalidateQueries({ queryKey: ['user', 'init'] }),

  // Cache subscription
  subscribeToCache: (callback: (event: any) => void) => {
    return queryClient.getQueryCache().subscribe(callback)
  },

  // Initialize cache subscriptions
  nearMapUsageTrackerQueryClientSubscription: () => {
    queryClientManager.subscribeToCache(event => {
      try {
        if (
          // only trigger nearmapUsageTracker.loginListener if the user has logged in at least once
          event.type === 'updated' &&
          event.query.queryKey[0] === 'user' &&
          event.query.queryKey[1] === 'init' &&
          event.action.type === 'success' &&
          event.action.data?.success
        ) {
          nearmapUsageTracker.loginListener()
        }
      } catch (error) {
        console.error('Error subscribing to user query changes:', error)
      }
    })
  },
}

// Export the QueryClient instance for React Query Provider
export { queryClient }
