import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useUser } from '@/queries/hooks/use-user'

import AsyncFetch from '../../utilities/AsyncFetch'

import { apis } from '../../config/apiConfig'

import dropdownScss from '../CSSModules/Dropdown.scss'
import sidebarScss from '../CSSModules/GlobalSidebar.scss'
import tocScss from '../CSSModules/GlobalToc.scss'
import appScss from '../App/App.scss'
import scss from './Profile.scss'

import * as utils from '@/utilities/util'
import { getMapHomeLocation } from '@/utilities/user'
import ZoomToPoint from '@/components/ZoomToPoint/ZoomToPoint'

import Icon from '@/components/Icon/Icon'

import { setToc } from '../../actions'

import { useDataConfig } from '@/contexts/DataConfigProvider'

export default function Sidebar({
  setSidebarOpen,
  setSidebarTransitions,
  logout,
}) {
  const { t } = useTranslation()
  const { data: user } = useUser()
  const viewport = useSelector(state => state.viewport)
  const dispatch = useDispatch()
  const { loadDataConfig } = useDataConfig()

  const [showOrgSwitcher, setOrgSwitcher] = useState(false)
  const [fetchingAccount, setFetchingAccount] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [homeLocation, setHomeLocation] = useState(null)
  const [homezoom, setHomezoom] = useState(null)
  const [isZooming, setIsZooming] = useState(false)

  const [profileImgURL, setProfileImgURL] = useState(
    'https://my-asset-map-data.s3.amazonaws.com/public/client_data/static/images/user.png'
  )
  const [accountImageURLs, setAccountImageURLs] = useState([])

  const toggleOrgSwitcher = () => {
    if (user.accounts.length > 1) {
      if (showOrgSwitcher) {
        setOrgSwitcher(false)
      } else setOrgSwitcher(true)
    }
  }

  const buildFetchParams = accountID => {
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'user/update'
    const body = {
      accountID,
    }

    setFetchObjects([{ url, method, body }])
    console.log('fetchObjects', fetchObjects)
    setFetchingAccount(true)
  }

  const goHome = () => {
    setIsZooming(true)
  }

  const handleZoomComplete = () => {
    setIsZooming(false)
  }

  const getHomeLocation = usr => {
    const location = getMapHomeLocation(usr)
    const coordsArray = [location.y, location.x]
    const zoom = parseFloat(location.z)

    setHomeLocation(coordsArray)
    setHomezoom(zoom)

    return { location, coordsArray, zoom }
  }

  const fetchFinished = async () => {
    loadDataConfig({
      updateStyle: false,
      resetStyle: true,
      refetchUser: true,
    }).then(result => {
      setFetchingAccount(false)

      console.log('fetchFinished result', result)
      const { location } = getHomeLocation(result.user)
      if (location) goHome()
    })

    setFetchObjects(null)
  }

  const updateAccount = accountID => {
    buildFetchParams(accountID)
  }

  const backToMap = () => {
    if (setSidebarOpen != null) {
      setSidebarOpen(false)
      dispatch(setToc(false))
    }
  }

  const btnClick = () => {
    if (setSidebarTransitions != null) setSidebarTransitions(false)
    if (setSidebarOpen != null) setSidebarOpen(false)
    dispatch(setToc(false))

    setTimeout(() => {
      if (setSidebarTransitions != null) setSidebarTransitions(true)
    }, 500)
  }

  const account = utils.objectKeyVal(user.accountID, user.accounts, 'id')

  // Profile Images
  useEffect(() => {
    async function getProfileImage() {
      const imgURL = await utils.getImage(user.profile.profileImg, 'user.png')

      setProfileImgURL(imgURL)
    }
    getProfileImage()
  }, [user])

  // Account Images
  const getAccountImages = async () => {
    return Promise.all(
      user.accounts.map(async acc => {
        const imgURL = await utils.getImage(acc.profileImg, 'account.png')

        return {
          id: acc.id,
          name: acc.name,
          image: imgURL,
        }
      })
    )
  }

  useEffect(() => {
    async function getAccountURLS() {
      if (Array.isArray(user.accounts)) {
        getAccountImages().then(accountURLs => {
          setAccountImageURLs(accountURLs)
        })
      }
    }
    getAccountURLS()
  }, [user])

  // Sort Accounts Alphabetically
  user.accounts.sort(utils.objectSort('name'))

  return (
    <nav className={tocScss.tocProfile} onBlur={backToMap}>
      {isZooming && homeLocation && homezoom && (
        <ZoomToPoint
          coordinates={homeLocation}
          zoom={homezoom}
          onComplete={handleZoomComplete}
        />
      )}
      <div id={tocScss.userProfileSidebar}>
        <NavLink to='/'>
          <div onClick={backToMap} className={sidebarScss.sidebarHeader}>
            <Icon icon={['far', 'angle-double-left']} size='1x' />
          </div>
        </NavLink>

        <div className={appScss['marY-md']}></div>

        {fetchObjects && (
          <AsyncFetch
            fetchObjects={fetchObjects}
            fetchFinished={fetchFinished}
          />
        )}

        <div className={sidebarScss.sidebarProfile}>
          <img
            alt='profile for user'
            src={profileImgURL}
            className={scss.profilePic}
          />
          <div className={sidebarScss.headingOrg}>
            <div
              className={[dropdownScss.dropdown].join(' ')}
              onClick={toggleOrgSwitcher}
            >
              {fetchingAccount ? (
                <div>{t('sidebar.account.loading')}</div>
              ) : (
                <div>{account.name}</div>
              )}

              {!fetchingAccount && user.accounts.length > 1 && (
                <>
                  &nbsp;&nbsp;
                  <Icon
                    icon={[
                      'fa',
                      'chevron-' + (showOrgSwitcher ? 'up' : 'down'),
                    ]}
                    size='1x'
                  />
                </>
              )}
              {/* ----------------------- START OF ORG DROPDOWN ITEMS ---------------------- */}
              {showOrgSwitcher && (
                <>
                  <div
                    style={{ maxWidth: '100%' }}
                    className={dropdownScss.dropdownCover}
                    onClick={toggleOrgSwitcher}
                  />
                  <div
                    className={[
                      dropdownScss.dropdownContent,
                      dropdownScss.orgDropdownContent,
                    ].join(' ')}
                  >
                    {user.accounts.map(acc => {
                      const profileImgObj = utils.objectKeyVal(
                        acc.id,
                        accountImageURLs,
                        'id'
                      )

                      return (
                        <div
                          key={acc.id}
                          className={[
                            dropdownScss.dropdownItem,
                            dropdownScss.orgDropdownItem,
                          ].join(' ')}
                          onClick={() => updateAccount(acc.id)}
                        >
                          <img
                            alt='profile for account'
                            src={profileImgObj ? profileImgObj.image : ''}
                            className={[dropdownScss.orgDropdownPic].join(' ')}
                          />
                          <div className={dropdownScss.orgDropdownText}>
                            <h4>{acc.name}</h4>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </>
              )}
              {/* -------------------------------------------------------------------------- */}
            </div>
          </div>
          <NavLink to='/profile'>
            {user?.profile?.displayName}{' '}
            {user?.isAdmin ? t('sidebar.account.adminLabel') : ''}
          </NavLink>
        </div>

        <div className={sidebarScss.sidebarMain}>
          {fetchingAccount ? (
            <Icon icon='spinner' size='1x' fixedWidth spin />
          ) : (
            <>
              <NavLink to='/'>
                <div onClick={backToMap} className={sidebarScss.sidebarButtons}>
                  {t('sidebar.navigation.backToMap')}
                </div>
              </NavLink>
              <NavLink
                activeClassName={sidebarScss.selectedSidebarLink}
                to='/profile'
              >
                <div onClick={btnClick} className={sidebarScss.sidebarButtons}>
                  {t('sidebar.navigation.profileAndAccount')}
                </div>
              </NavLink>
              <NavLink
                activeClassName={sidebarScss.selectedSidebarLink}
                to='/layers'
              >
                <div onClick={btnClick} className={sidebarScss.sidebarButtons}>
                  {t('sidebar.navigation.data')}
                </div>
              </NavLink>
              {user.adminAccounts.length !== 0 && (
                <>
                  <NavLink
                    activeClassName={sidebarScss.selectedSidebarLink}
                    to='/maps'
                  >
                    <div
                      onClick={btnClick}
                      className={sidebarScss.sidebarButtons}
                    >
                      {t('sidebar.navigation.maps')}
                    </div>
                  </NavLink>
                  <NavLink
                    activeClassName={sidebarScss.selectedSidebarLink}
                    to='/users'
                  >
                    <div
                      onClick={btnClick}
                      className={sidebarScss.sidebarButtons}
                    >
                      {t('sidebar.navigation.users')}
                    </div>
                  </NavLink>

                  {utils.isSuperAdmin(user) && ( // checks to see if user is in adminAccountList array
                    <NavLink
                      activeClassName={sidebarScss.selectedSidebarLink}
                      to='/accounts'
                    >
                      <div
                        onClick={btnClick}
                        className={sidebarScss.sidebarButtons}
                      >
                        {t('sidebar.navigation.accounts')}
                      </div>
                    </NavLink>
                  )}
                </>
              )}

              <NavLink to='/' onClick={logout}>
                <div className={sidebarScss.sidebarLogout}>
                  {t('sidebar.navigation.signOut')}
                </div>
              </NavLink>
            </>
          )}
        </div>

        <div className={sidebarScss.sidebarFooter}>
          <NavLink to='/'>
            <span className={sidebarScss.sidebarLogo}>
              {t('sidebar.brand.name')}
            </span>
          </NavLink>
        </div>
      </div>
    </nav>
  )
}
