export const SET_VIEWPORT = 'SET_VIEWPORT'
export const GET_VIEW_PORT = 'GET_VIEW_PORT'
export const SET_MAP_STYLE = 'SET_MAP_STYLE'
export const TOGGLE_MAP_STYLE = 'TOGGLE_MAP_STYLE'
export const UPDATE_MAP_STYLE = 'UPDATE_MAP_STYLE'
export const UPDATE_TOOL_CONFIG = 'UPDATE_TOOL_CONFIG'
export const SET_TOOL_CONFIG = 'SET_TOOL_CONFIG'
export const DOUBLE_CLICK_ZOOM = 'DOUBLE_CLICK_ZOOM'
export const SET_DRAG_PAN = 'DRAG_PAN'
export const CREATE_POPUP = 'CREATE_POPUP'
export const SET_MAP_CURSOR = 'SET_MAP_CURSOR'
export const SET_MAP_MODE = 'SET_MAP_MODE'
export const UPDATE_DATA_CONFIG = 'UPDATE_DATA_CONFIG'
export const SWITCH_PAGE = 'SWITCH_PAGE'
export const SET_DATA_CONFIG = 'SET_DATA_CONFIG'
export const REBUILD_MAP_STYLE = 'REBUILD_MAP_STYLE'
export const MOUNT_LAYER_SETTINGS = 'MOUNT_LAYER_SETTINGS'
export const MOUNT_MAP_VIEWS = 'MOUNT_MAP_VIEWS'
export const INCREMENT_MOUNT_VIEWS = 'INCREMENT_MOUNT_VIEWS'
export const SET_MOUNT_MAPVIEWS = 'SET_MOUNT_MAPVIEWS'
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM'
export const OPEN_NOTES = 'OPEN_NOTES'
export const TOC_COLLAPSED = 'TOC_COLLAPSED'
export const ENABLE_MAP = 'ENABLE_MAP'
export const SET_SCROLL_ZOOM = 'SET_SCROLL_ZOOM'
export const SET_OVER_PANEL = 'SET_OVER_PANEL'
export const DISABLE_POPUP = 'DISABLE_POPUP'
export const SET_POPUP_DOCKED = 'SET_POPUP_DOCKED'
export const SET_DRAW_MODE = 'SET_DRAW_MODE'
export const SET_MEASURE_MODE = 'SET_MEASURE_MODE'
export const SET_SNAP_SETTINGS = 'SET_SNAP_SETTINGS'
export const SET_DRAW_MEASURE_SETTINGS = 'SET_DRAW_MEASURE_SETTINGS'
export const SET_BOUNDS_SETTINGS = 'SET_BOUNDS_SETTINGS'
export const INITIATE_TRACKING = 'INITIATE_TRACKING'
export const UPDATE_CURRENT_LOCATION = 'UPDATE_CURRENT_LOCATION'
export const TERMINATE_TRACKING = 'TERMINATE_TRACKING'
export const FILTER_CHANGED = 'FILTER_CHANGED'
export const SET_MAP_REF = 'SET_MAP_REF'
export const SET_FOOTER_HEIGHT = 'SET_FOOTER_HEIGHT'
export const SET_ASIDE_WIDTH = 'SET_ASIDE_WIDTH'
export const SET_DATA_TABLE = 'SET_DATA_TABLE'
export const SET_SELECT_FEATURES = 'SET_SELECT_FEATURES'
export const SET_LAYER_LABELS = 'SET_LAYER_LABELS'
export const SET_MAP_TOOLTIP = 'SET_MAP_TOOLTIP'
export const SET_MAP_TOOL_TIP = 'SET_MAP_TOOL_TIP'
export const SET_VISIBLE_LAYERS = 'SET_VISIBLE_LAYERS'
export const SET_TOC_LAYERS_FILTER = 'SET_TOC_LAYERS_FILTER'
export const GROUP_CLICKED = 'GROUP_CLICKED'
export const LAYER_CLICKED = 'LAYER_CLICKED'
export const SET_ACCOUNT_KEY = 'SET_ACCOUNT_KEY'
export const TOGGLE_MAP_LAYER = 'TOGGLE_MAP_LAYER'
export const TOGGLE_MAP_LAYERS_ON = 'TOGGLE_MAP_LAYERS_ON'
export const TOGGLE_MAP_LAYERS_OFF = 'TOGGLE_MAP_LAYERS_OFF'

//User
export const GET_INFO = 'GET_INFO'
export const LOGIN = 'LOGIN'
export const REFRESH = 'REFRESH'
export const ALERT = 'ALERT'
export const LOGOUT = 'LOGOUT'

export const ERROR = 'ERROR'
