import { apiFetch } from './api'
import { apis } from '../config/apiConfig'
import LogRocket from 'logrocket'
import { queryClientManager } from './queryClientManager'

const REPORT_USAGE_INTERVAL = 3000
const DELETE_EXPIRED_ENTRIES_FROM_CACHE_INTERVAL = 60_000

let nearmapUsageTracker = {
  billableUsageInBytes: 0,
  totalUsageInBytes: 0,
  previouslyLoggedIn: false,
  reportUsageIntervalId: null,
  deleteExpiredEntriesFromCacheIntervalId: null,
  loginListener: () => {
    const userData = queryClientManager.getUserData()
    const isLoggedIn = userData?.isLoggedIn ?? false

    if (!nearmapUsageTracker.previouslyLoggedIn && isLoggedIn) {
      nearmapUsageTracker.reportUsageIntervalId = setInterval(
        nearmapUsageTracker.reportUsage,
        REPORT_USAGE_INTERVAL
      )
      nearmapUsageTracker.deleteExpiredEntriesFromCacheIntervalId = setInterval(
        nearmapUsageTracker.deleteExpiredEntriesFromCache,
        DELETE_EXPIRED_ENTRIES_FROM_CACHE_INTERVAL
      )
    } else if (nearmapUsageTracker.previouslyLoggedIn && !isLoggedIn) {
      clearInterval(nearmapUsageTracker.reportUsageIntervalId)
      clearInterval(nearmapUsageTracker.deleteExpiredEntriesFromCacheIntervalId)
    }
    nearmapUsageTracker.previouslyLoggedIn = isLoggedIn
  },
  fetchWithNearmapCache: request => {
    if (request.url.includes('api.nearmap')) {
      return caches
        .open('nearmap-cache')
        .then(cache => cache.match(request))
        .then(response => {
          const requestIsCached = response !== undefined
          if (requestIsCached) {
            return response
          } else {
            request = new Request(request, { cache: 'no-store' })
            return nearmapUsageTracker.fetchAndStoreInNearmapCache(request)
          }
        })
        .catch(e => {
          nearmapUsageTracker.handleFetchAbortedError(e)
        })
    } else {
      return fetch(request)
    }
  },
  fetchAndStoreInNearmapCache: request => {
    const parts = request.url.split('/')
    const zoomLevelIndex = parts.indexOf('Vert') + 1
    const zoomLevel = parseInt(parts[zoomLevelIndex])
    return fetch(request).then(response => {
      if (response.ok) {
        const cloneOne = response.clone()
        const cloneTwo = response.clone()
        nearmapUsageTracker.putInNearmapCache(request, cloneOne)
        nearmapUsageTracker.storeSize(cloneTwo, zoomLevel)
      }
      return response
    })
  },
  storeSize: (response, zoomLevel) => {
    response
      .arrayBuffer()
      .then(res => {
        if (zoomLevel >= 14) {
          nearmapUsageTracker.billableUsageInBytes += res.byteLength
        }
        nearmapUsageTracker.totalUsageInBytes += res.byteLength
      })
      .catch(e => {
        nearmapUsageTracker.handleFetchAbortedError(e)
      })
  },
  putInNearmapCache: (request, response) => {
    caches
      .open('nearmap-cache')
      .then(cache => cache.put(request, response))
      .catch(e => {
        nearmapUsageTracker.handleFetchAbortedError(e)
      })
  },
  deleteExpiredEntriesFromCache: () => {
    let nearmapCache
    caches
      .open('nearmap-cache')
      .then(cache => {
        nearmapCache = cache
        return cache.keys()
      })
      .then(keys => {
        keys.forEach(key => {
          nearmapCache
            .match(key)
            .then(response => {
              const expires = Date.parse(response.headers.get('expires'))
              if (isNaN(expires)) {
                return Promise.reject(
                  'Unexpected error: Cannot parse expires header.'
                )
              }
              const now = Date.now()
              if (now >= expires) {
                return nearmapCache.delete(key)
              }
            })
            .catch(e => {
              LogRocket.captureException(e)
            })
        })
      })
  },
  reportUsage: () => {
    const billableUsageInBytes = nearmapUsageTracker.billableUsageInBytes
    const totalUsageInBytes = nearmapUsageTracker.totalUsageInBytes
    nearmapUsageTracker.billableUsageInBytes = 0
    nearmapUsageTracker.totalUsageInBytes = 0
    if (billableUsageInBytes > 0 || totalUsageInBytes > 0) {
      const userData = queryClientManager.getUserData()
      if (!userData) return

      const url = apis['apiDatabase'].uri + 'user/usage'
      const method = 'POST'
      const body = {
        accountID: userData?.accountID || null,
        userID: userData?.profile?.userID || null,
        sizeBytes: billableUsageInBytes,
        totalSizeBytes: totalUsageInBytes,
      }
      if (!body.accountID || !body.userID) {
        console.error('Missing required IDs for usage reporting')
        LogRocket.captureMessage('Invalid IDs in usage report', {
          extra: { body },
        })
        return
      }
      apiFetch(url, method, body, res => {
        if (!res.success) {
          LogRocket.captureException(res)
          console.log('window reload', res)
          // Reactive reload after testing
          // window.location.reload()
        }
      })
    }
  },
  handleFetchAbortedError: e => {
    const abortErrorChrome = e instanceof DOMException
    const abortErrorFirefox =
      e.hasOwnProperty('name') && e.name === 'NS_BINDING_ABORTED'
    const abortErrorSafari =
      e.hasOwnProperty('message') &&
      e.message === 'AbortError: Fetch is aborted'
    const errorIsExpected =
      abortErrorChrome || abortErrorFirefox || abortErrorSafari
    if (!errorIsExpected) {
      LogRocket.captureException(e)
    }
  },
}

export default nearmapUsageTracker
