import { apis } from '@/config/apiConfig'
import { queryFetch } from '@/queries/api'
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_ZOOM,
} from '@/containers/Map/Viewport/Constants'
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
  UseMutationResult,
  UseQueryOptions,
} from '@tanstack/react-query'
import { UserData, UserInitResponse } from '@/types/user'
import { apiFetch } from '@/utilities/api'
import { verifyResult } from '@/utilities/utils'

// Default user state
export const defaultUser = (): UserData => ({
  UUID: '',
  isSuperAdmin: false,
  isAdmin: false,
  isActive: false,
  role: undefined,
  permissions: undefined,
  adminAccounts: [],
  profile: undefined,
  accounts: [],
  preferences: undefined,
  accountID: 0,
  mapID: 0,
  isLoggedIn: false,
})

// Query key factory
export const userKeys = {
  all: ['user'] as const,
  init: () => [...userKeys.all, 'init'] as const,
  preferences: () => [...userKeys.all, 'preferences'] as const,
}

// user fetch function and data setup
export const fetchUserInit = async () => {
  const response: UserInitResponse = await new Promise((resolve, reject) => {
    apiFetch(`${apis.apiDatabase.uri}user/init`, 'GET', null, result => {
      if (result.success) {
        resolve(result)
      } else {
        reject(new Error(result.message || 'Failed to fetch user data.'))
      }
    })
  })

  return response
}

const verifyUserData = (
  data: UserInitResponse
): data is UserInitResponse & { data: UserData } => {
  return verifyResult(data) && data.success && !!data.data
}

// Main user query hook
export const useUser = (
  options?: UseQueryOptions<UserInitResponse, Error, UserData>
): UseQueryResult<UserData, Error> => {
  const queryResult = useQuery<UserInitResponse, Error, UserData>({
    queryKey: userKeys.init(),
    queryFn: fetchUserInit,
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: false,
    select: data => {
      if (verifyUserData(data)) {
        const {
          profile,
          isSuperAdmin,
          isAdmin,
          isActive,
          role,
          permissions,
          adminAccounts,
          accounts = [],
          preferences,
          accountID,
          mapID,
        } = data.data

        const user = defaultUser()
        user.UUID = data.userUUID ?? ''

        // Handle account selection and validation
        if (!accounts.length) {
          throw new Error(
            'You are not a part of any accounts. Please contact support@myassetmap.com.'
          )
        }

        // Validate first account has an ID if we need to use it as fallback
        const firstValidAccount = accounts.find(acc => acc.id != null)
        if (!firstValidAccount) {
          throw new Error(
            'Invalid account data. Please contact support@myassetmap.com.'
          )
        }

        // Set accountID with fallback to first valid account if none specified
        user.accountID = accountID ?? firstValidAccount.id
        const account = accounts.find(acc => acc.id === user.accountID)

        if (!account) {
          throw new Error(
            'No active accounts have been found. Please have your admin add to you an account.'
          )
        }

        // Set user permissions and role based on account
        user.isAdmin =
          isAdmin || adminAccounts?.includes(user.accountID) || false
        user.isActive = isActive
        user.role = role
        user.permissions = permissions
        user.adminAccounts = adminAccounts
        user.accounts = accounts

        // Handle map selection with fallbacks
        user.mapID = mapID ?? account.maps?.[0]?.id ?? null

        // Set remaining user data
        user.profile = profile
        user.preferences = preferences
        user.isSuperAdmin = isSuperAdmin

        // Validate required data exists
        if (!user.UUID || !user.accountID || !user.mapID) {
          throw new Error(
            'Missing required user data. Please contact support@myassetmap.com.'
          )
        }

        user.isLoggedIn = true
        return user
      }
      return defaultUser()
    },
    ...options,
  })

  return queryResult
}
// Update user mutation hook

// @TODO, needs updating to not use queryFetch but the original apiFetch
export const useUpdateUser = (): UseMutationResult<
  any,
  Error,
  Partial<UserData>
> => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, Partial<UserData>>({
    mutationFn: (updates: Partial<UserData>) =>
      queryFetch({
        url: `${apis.apiDatabase.uri}user/update`,
        method: 'POST',
        body: updates,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userKeys.init() })
    },
  })
}

// Helper function
export const getMapHomeLocation = (user: UserData) => {
  const accountId = user?.accountID
  const mapId = user?.mapID
  const activeAccount = user?.accounts?.find(
    account => account.id === accountId
  )

  if (!activeAccount) {
    return { x: DEFAULT_LATITUDE, y: DEFAULT_LONGITUDE, z: DEFAULT_ZOOM }
  }

  const map = activeAccount?.maps?.find(map => map.id === mapId)
  return (
    map?.location || {
      x: DEFAULT_LATITUDE,
      y: DEFAULT_LONGITUDE,
      z: DEFAULT_ZOOM,
    }
  )
}

export const getActiveMap = (user: UserData) => {
  const accountId = user?.accountID
  const mapId = user?.mapID
  const activeAccount = user?.accounts?.find(
    account => account.id === accountId
  )
  return activeAccount?.maps?.find(map => map.id === mapId)
}
