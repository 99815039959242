import React, {
  useState,
  useEffect,
  useCallback,
  useTransition,
  Suspense,
  lazy,
} from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import Sidebar from 'react-sidebar'
import { LicenseManager } from 'ag-grid-enterprise'
import { Auth } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'

// Config imports
import { keys } from '@/config/keyConfig'
import * as utils from '@/utilities/util'

// Import hooks
import { queryClientManager } from '@/utilities/queryClientManager'
import { useUser, defaultUser } from '@/queries/hooks/use-user'
import { useAnalytics } from '@/utilities/Hooks/useAnalytics'

// Action Handlers
import {
  Login as _Login,
  Logout as _Logout,
  updateDataConfig as _updateDataConfig,
  setToc as _setToc,
  Alert as _Alert,
} from '@/actions'

// Import non-lazy components
import Footer from '../Footer/Footer'
import Login from '../Login/Login'
import Reset from '../Login/Reset'
import Verify from '../Login/Verify'
import Loading from './Loading'
import ErrorBoundary from './ErrorBoundary.jsx'
import Error404 from '../Error404'
import MakeDataConfigComponent from '@/utilities/dataConfig'
import { createCredentials } from '@/utilities/user'
import ProfileSidebar from '../Profile/Sidebar'

// Styles
import scss from './App.scss'

// Lazy load route components
const Map = lazy(() => import('../Map/Map'))
const Debug = lazy(() => import('../Profile/Debug'))
const Profile = lazy(() => import('../Profile/Profile'))
const Layers = lazy(() => import('../Profile/Layers'))
const Maps = lazy(() => import('../Profile/Maps'))
const Users = lazy(() => import('../Profile/Users'))
const Accounts = lazy(() => import('../Profile/Accounts'))
const Toc = lazy(() => import('../Toc/Toc'))

// Route configurations
const ROUTES = {
  HOME: '/',
  DEBUG: '/debug',
  PROFILE: '/profile',
  LAYERS: '/layers',
  MAPS: '/maps',
  USERS: '/users',
  ACCOUNTS: '/accounts',
  LOGIN: '/login',
  LOGOUT: '/logout',
  RESET: '/reset',
  VERIFY: '/verify',
}

// Protected route component
const ProtectedRoute = ({ children, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isLoggedIn) {
          return children
        }

        return (
          <Redirect
            to={{
              pathname: ROUTES.LOGIN,
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

const App = () => {
  const [isPending, startTransition] = useTransition()
  const dispatch = useDispatch()
  const { data: user, refetch: refetchUser } = useUser()

  const dataConfig = useSelector(state => state.updateDataConfig)
  const tocCollapsed = useSelector(state => state.tocCollapsed)
  const accountKey = useSelector(state => state.accountKey)

  const [appState, setAppState] = useState({
    isAuthenticating: true,
    fetchingDataConfig: false,
    mountMakeDataConfig: false,
    sidebarOpen: false,
    sidebarTransitions: true,
    helpWidgetActive: false,
  })

  // Only initialize analytics after authentication is complete and we have valid user data
  const shouldInitAnalytics =
    !appState.isAuthenticating && user?.UUID && user.isLoggedIn
  useAnalytics(shouldInitAnalytics ? user : null)

  // Memoize setUserData to avoid dependency array issues
  const setUserData = useCallback(data => {
    queryClientManager.setUserData(data)
  }, [])

  // Initialize Google Maps and other services
  useEffect(() => {
    const initializeServices = async () => {
      try {
        // Check if Google Maps script already exists
        const existingScript = document.querySelector(
          'script[src*="maps.googleapis.com"]'
        )
        if (!existingScript) {
          // Load Google Maps script
          const script = document.createElement('script')
          script.src = `https://maps.googleapis.com/maps/api/js?key=${keys.google}&libraries=places`
          await script.load
          document.head.append(script)
        }

        // Initialize license
        LicenseManager.setLicenseKey(keys.agGrid)

        // Set TOC state
        dispatch(_setToc(false))
      } catch (error) {
        console.error('Service initialization failed:', error)
        dispatch(_Alert({ error: 'Failed to initialize services' }))
      }
    }

    initializeServices()
  }, [dispatch])

  // Handle authentication
  useEffect(() => {
    const authenticateUser = async () => {
      try {
        await createCredentials()
        const { data: userData } = await refetchUser()

        // Validate user data
        if (Object.keys(userData).length === 0 || !userData?.UUID) {
          throw new Error('Invalid user data received')
        }
      } catch (error) {
        if (error !== 'No current user') {
          console.error('Authentication error:', error)
          utils.showMessage('Session Auth Error', error.message)
          // Ensure user is logged out on error
          setUserData(defaultUser())
        }
      } finally {
        setAppState(prev => ({ ...prev, isAuthenticating: false }))
      }
    }

    authenticateUser()
  }, [refetchUser, setUserData])

  // Handle data config updates
  useEffect(() => {
    const loadDataConfig = async () => {
      if (
        !appState.fetchingDataConfig &&
        user?.isLoggedIn &&
        user?.mapID &&
        !appState.mountMakeDataConfig &&
        !dataConfig // Only load if we don't have data config yet
      ) {
        try {
          setAppState(prev => ({
            ...prev,
            fetchingDataConfig: true,
            mountMakeDataConfig: true,
          }))
        } catch (error) {
          console.error('Error loading data config:', error)
          setAppState(prev => ({
            ...prev,
            fetchingDataConfig: false,
            mountMakeDataConfig: false,
          }))
          dispatch(_Alert({ error: 'Failed to load data configuration' }))
        }
      }
    }

    loadDataConfig()
  }, [
    user?.isLoggedIn,
    user?.mapID,
    appState.fetchingDataConfig,
    appState.mountMakeDataConfig,
    dataConfig,
    dispatch,
  ])

  // Handle data config completion
  const handleDataConfigFinish = useCallback(() => {
    console.log('dataConfig done')
    setAppState(prev => ({
      ...prev,
      mountMakeDataConfig: false,
      fetchingDataConfig: false,
    }))
  }, [])

  const handleLogout = useCallback(async () => {
    try {
      await Auth.signOut()
      // Update the user cache to default state
      setUserData(defaultUser())
    } catch (error) {
      console.error('Logout failed:', error)
      utils.showMessage('Logout Failed', error.message)
    }
  }, [setUserData])

  const handleLoginAttempt = useCallback(
    async (success = false, userPayload = '') => {
      if (success) {
        try {
          // First save AWS credentials to local storage.
          await createCredentials()
          // Next, fetch user data.
          const { data: userData } = await refetchUser()

          // Validate user data.
          if (!Object.keys(userData).length > 0 || !userData?.UUID) {
            throw new Error('Invalid user data received')
          }
        } catch (error) {
          console.error('Login failed:', error)
          utils.showMessage('Login Failed', error.message)
          // Reset user cache to default state on login failure
          setUserData(defaultUser())
        }
      }
    },
    [setUserData, refetchUser]
  )

  const setSidebarOpen = useCallback(open => {
    setAppState(prev => ({ ...prev, sidebarOpen: open }))
  }, [])

  const setSidebarTransitions = useCallback(trans => {
    setAppState(prev => ({ ...prev, sidebarTransitions: trans }))
  }, [])

  const getMapUi = useCallback(
    config => {
      const showMap = Boolean(config)
      const tocWidth = tocCollapsed ? '72px' : 'auto'

      if (!showMap) {
        return <Loading />
      }

      return (
        <Suspense fallback={<Loading />}>
          <div className={scss.sidebarHolder}>
            <Sidebar
              sidebar={
                <ProfileSidebar
                  setToc={collapsed => dispatch(_setToc(collapsed))}
                  setSidebarOpen={setSidebarOpen}
                  setSidebarTransitions={setSidebarTransitions}
                  logout={handleLogout}
                />
              }
              transitions={appState.sidebarTransitions}
              open={appState.sidebarOpen}
              onSetOpen={setSidebarOpen}
              styles={{
                root: { zIndex: 2 },
                sidebar: {
                  background: '#1A2937',
                  borderRadius: '0px 10px 10px 0px',
                },
              }}
            >
              <div
                style={{ gridTemplateColumns: `${tocWidth} 1fr` }}
                className={scss.container}
              >
                <Switch>
                  <Route
                    exact
                    path={ROUTES.HOME}
                    render={props => (
                      <Toc
                        {...props}
                        setSidebarOpen={setSidebarOpen}
                        dataConfig={config}
                      />
                    )}
                  />
                  <Route
                    path='/(debug|profile|layers|maps|teams|users|accounts)/'
                    render={() => <ProfileSidebar logout={handleLogout} />}
                  />
                  <Route render={props => <Error404 {...props} type='map' />} />
                </Switch>

                <main id='main' className={scss.main}>
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      <ProtectedRoute
                        exact
                        path={ROUTES.DEBUG}
                        isLoggedIn={user?.isLoggedIn}
                      >
                        <Debug />
                      </ProtectedRoute>
                      <ProtectedRoute
                        exact
                        path={ROUTES.PROFILE}
                        isLoggedIn={user?.isLoggedIn}
                      >
                        <Profile />
                      </ProtectedRoute>
                      <ProtectedRoute
                        exact
                        path={ROUTES.LAYERS}
                        isLoggedIn={user?.isLoggedIn}
                      >
                        <Layers />
                      </ProtectedRoute>
                      <ProtectedRoute
                        exact
                        path={ROUTES.MAPS}
                        isLoggedIn={user?.isLoggedIn}
                      >
                        <Maps />
                      </ProtectedRoute>
                      <ProtectedRoute
                        exact
                        path={ROUTES.USERS}
                        isLoggedIn={user?.isLoggedIn}
                      >
                        <Users />
                      </ProtectedRoute>
                      <ProtectedRoute
                        exact
                        path={ROUTES.ACCOUNTS}
                        isLoggedIn={user?.isLoggedIn}
                      >
                        <Accounts />
                      </ProtectedRoute>
                      <Route
                        path={ROUTES.HOME}
                        render={props => (
                          <Map
                            key={accountKey}
                            {...props}
                            dataConfig={config}
                            noPreferences='noPreferences'
                          />
                        )}
                      />
                    </Switch>
                  </Suspense>
                </main>
                <footer id='footer' className={scss.footer}>
                  <Footer />
                </footer>
              </div>
            </Sidebar>
          </div>
        </Suspense>
      )
    },
    [
      appState.mountMakeDataConfig,
      appState.sidebarOpen,
      appState.sidebarTransitions,
      tocCollapsed,
      handleLogout,
      dispatch,
      accountKey,
    ]
  )

  const getLoginUi = useCallback(() => {
    if (appState.isAuthenticating) return <Loading />

    return (
      <Switch>
        <div style={{ width: '100%', margin: '15% 0' }}>
          <Route
            exact
            path={[ROUTES.HOME, ROUTES.LOGIN]}
            render={props => (
              <Login {...props} handleLoginAttempt={handleLoginAttempt} />
            )}
          />
          <Route
            path={ROUTES.RESET}
            render={props => (
              <Reset {...props} handleLoginAttempt={handleLoginAttempt} />
            )}
          />
          <Route
            path={ROUTES.VERIFY}
            render={props => (
              <Verify {...props} handleLoginAttempt={handleLoginAttempt} />
            )}
          />
          <Route
            path='/(debug|profile|layers|maps|teams|users|accounts)/'
            render={props => (
              <Login {...props} handleLoginAttempt={handleLoginAttempt} />
            )}
          />
          <Route render={props => <Error404 {...props} type='login' />} />
        </div>
      </Switch>
    )
  }, [appState.isAuthenticating, handleLoginAttempt])

  const renderContent = useCallback(() => {
    if (!user) return getLoginUi()

    if (user.isLoggedIn) {
      if (window.location.pathname === ROUTES.VERIFY) {
        return <Redirect to={ROUTES.HOME} />
      }

      if (user.accounts?.length > 0) {
        return getMapUi(dataConfig)
      }

      return (
        <div className='text-center pad-lg'>
          <p>It looks like you are not a part of any accounts.</p>
          <p>
            Please talk to your account administrator and have them add you to
            an account.
          </p>
        </div>
      )
    }

    return getLoginUi()
  }, [user, dataConfig, getLoginUi, getMapUi])

  const isLocalEnv =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'

  return (
    <ErrorBoundary refreshPage={!isLocalEnv}>
      <Suspense fallback={<Loading />}>
        {isPending && <Loading />}
        {appState.mountMakeDataConfig && (
          <MakeDataConfigComponent
            onFinish={handleDataConfigFinish}
            updateStyle={false}
          />
        )}
        {renderContent()}
      </Suspense>
    </ErrorBoundary>
  )
}

App.propTypes = {
  user: PropTypes.shape({
    accounts: PropTypes.arrayOf(PropTypes.shape({})),
    isLoggedIn: PropTypes.bool.isRequired,
    accountID: PropTypes.number,
    mapID: PropTypes.number,
    UUID: PropTypes.string.isRequired,
    profile: PropTypes.shape({
      displayName: PropTypes.string,
      emailAddress: PropTypes.string,
    }).isRequired,
  }),
  SetToc: PropTypes.func.isRequired,
  Login: PropTypes.func.isRequired,
  Logout: PropTypes.func.isRequired,
  tocCollapsed: PropTypes.bool.isRequired,
  accountKey: PropTypes.string.isRequired,
  dataConfig: PropTypes.shape({}),
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default App
