import './i18n'
import './styles/tailwind.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import environment from '_environments'

import { transitions, positions, Provider as AlertProvider } from 'react-alert'

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClientProvider } from '@tanstack/react-query'
import reducers from './reducers'
import throttledMiddleware from './middleware/throttle'
import { LanguageProvider } from './contexts/LanguageContext'
// eslint-disable-next-line import/no-cycle
import App from './containers/App/App.jsx'

import {
  configureAmplify,
  configureAnalytics,
  configureFontAwesome,
  configureS3,
  configureSentry,
} from './services'

import AlertTemplate from '@/react-alert-template'

import nearmapUsageTracker from '@/utilities/nearmapUsageTracker'
import Alert from '@/components/Alert.js'

import { queryClient, queryClientManager } from '@/utilities/queryClientManager'
import { DataConfigProvider } from '@/contexts/DataConfigProvider'

configureAmplify()
configureFontAwesome()
configureS3()
configureSentry()
configureAnalytics()

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(throttledMiddleware)
    // other store enhancers if any
  )
)

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 1000,
  offset: '12px 0 0 0',
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 1301,
    bottom: '100px',
    left: 'unset',
    right: '32px',
  },
}

const container =
  document.getElementById('root') || document.createElement('div')
const root = createRoot(container)

// Initialize nearMapUsageTracker query client subscription
queryClientManager.nearMapUsageTrackerQueryClientSubscription()

root.render(
  <QueryClientProvider client={queryClient}>
    <LanguageProvider>
      <Provider store={store}>
        <BrowserRouter>
          <DataConfigProvider>
            <AlertProvider template={AlertTemplate} {...options}>
              <Alert />
              <App />
            </AlertProvider>
          </DataConfigProvider>
        </BrowserRouter>
      </Provider>
    </LanguageProvider>
    {environment?.isDev ? <ReactQueryDevtools initialIsOpen={false} /> : null}
  </QueryClientProvider>
)

export { store }
