/**
 * Find an object in an array by matching a key's value
 */
export const objectKeyVal = <T, K extends keyof T>(
  val: T[K] | null,
  array: T[],
  key: K
): T | undefined => {
  if (!val || !array?.length) return undefined
  return array.find(item => item[key] === val)
}

/**
 * Show a message to the user
 */
export const showMessage = (title: string = '', msg: string = ''): void => {
  // TODO: Implement actual message display logic
  console.log(`${title}: ${msg}`)
}

export const verifyResult = (result: unknown): boolean => {
  if (Array.isArray(result)) {
    return result.length >= 2 && Boolean(result[0])
  }
  if (typeof result === 'object' && result !== null) {
    return (
      'success' in result && Boolean((result as { success: boolean }).success)
    )
  }
  return false
}

export const resultReturn = (result: unknown): unknown | null => {
  if (Array.isArray(result)) {
    return result.length >= 3 ? result[2] : null
  }
  if (typeof result === 'object' && result !== null) {
    return 'data' in result ? (result as { data: unknown }).data : null
  }
  return null
}
