import React, { useState, useEffect } from 'react'
import { GOOGLE, NEARMAP } from '../data/Imagery/Imagery'
// import { Common } from "../data/Layers";
import { DRAW } from '../data/Layers/Auxiliary/Draw'
import { MEASURE } from '../data/Layers/Auxiliary/Measure'
import { MEASURE_CONTROL } from '../data/Layers/Auxiliary/MeasureControl'
import { DELETE } from '../data/Layers/Auxiliary/Delete'
import { COORDINATE_SEARCH } from '../data/Layers/Auxiliary/Search'
import { GEOCODE } from '../data/Layers/Auxiliary/Search'
import { CLICKED_FEATURE } from '../data/Layers/Auxiliary/ClickedFeature'
import { SELECTED_FEATURES } from '../data/Layers/Auxiliary/SelectedFeatures'
import { GEO_SPATIAL_RESULTS } from '../data/Layers/Auxiliary/GeoSpatialResults'
import { apiFetch } from '../utilities/api'
import { apis } from '../config/apiConfig'
import { clone } from './geospatial'

import { useDispatch, useSelector } from 'react-redux'
import { updateDataConfig, updateMapStyle } from '../actions'
import { fromJS } from 'immutable'
import {
  addLayerToStyle,
  addSourceToStyle,
  addTocLayersToMapStyle2,
} from './mapStyle'
import { BASIC_STYLE } from '../mapStyles/basic/style.js'
import AsyncFetch from '../utilities/AsyncFetch'

import * as utils from './util'
import { useUser } from '@/queries/hooks/use-user'

const build_userLayersArray = (userLayers, masterCommonlayerList) => {
  //If undefined, update all entries.

  if (typeof userLayers !== 'object')
    return Object.values(masterCommonlayerList)

  const layers = processUserLayers(userLayers, masterCommonlayerList)
  return layers
}

const processUserLayers = (userLayers, masterCommonLayerList) => {
  // we use react beautiful drag and drop to handle layer dragging in the toc
  // rbdnd requires each draggable item to have a sequential index property
  // due to layer grouping we need to manually set this index for each layer
  // add draggable index to group layers
  // let preprocess = []

  const layers = getLayers(userLayers, masterCommonLayerList)

  const groups = layers.filter(layer => layer.children)

  let draggableIndex = null
  groups.forEach(group => {
    draggableIndex = 1

    //START Children & Ordering Logic
    var newOrder = []
    var layerList = []
    group.children.groupIds.forEach(id => layerList.push(id))
    group.children.layerIds.forEach(id => layerList.push(id))

    if (group.children.order && group.children.order.length) {
      var unprocessedChildren = layerList
      if (layerList.length) {
        group.children.order.forEach(orderID => {
          if (layerList.includes(orderID)) {
            unprocessedChildren = utils.unVal(unprocessedChildren, orderID)

            let viewObj = layers.filter(
              layer =>
                layer.id === orderID || (layer.toc && layer.toc.id === orderID)
            )
            if (viewObj[0] && viewObj[0].toc) {
              viewObj[0].toc.draggableIndex = draggableIndex
              draggableIndex++
            } else if (viewObj[0]) {
              viewObj[0].draggableIndex = draggableIndex
              draggableIndex++
            }
            newOrder.push(viewObj[0])
          }
        })
      }

      //Process the rest of them that were not in the order array.
      if (unprocessedChildren.length) {
        unprocessedChildren.forEach(unID => {
          let viewObj = layers.filter(
            layer => layer.id === unID || (layer.toc && layer.toc.id === unID)
          )
          if (viewObj[0] && viewObj[0].toc) {
            viewObj[0].toc.draggableIndex = draggableIndex
            draggableIndex++
          } else if (viewObj[0]) {
            viewObj[0].draggableIndex = draggableIndex
            draggableIndex++
          }
          newOrder.push(viewObj[0])
        })
      }
    } else {
      layerList.forEach(layerID => {
        let viewObj = layers.filter(
          layer =>
            layer.id === layerID || (layer.toc && layer.toc.id === layerID)
        )
        if (viewObj[0] && viewObj[0].toc) {
          viewObj[0].toc.draggableIndex = draggableIndex
          draggableIndex++
        } else if (viewObj[0]) {
          viewObj[0].draggableIndex = draggableIndex
          draggableIndex++
        }
        newOrder.push(viewObj[0])
      })
    }
    newOrder = newOrder.filter(el => el != null)
    group.children.items = newOrder
    //END Children Logic

    // remove layer or group from list
    group.children.items.forEach(child => {
      if (child.children) {
        const index = layers.findIndex(
          layer => layer.groupId && layer.groupId === child.groupId
        )
        layers.splice(index, 1)
      } else if (child.toc.id) {
        const index = layers.findIndex(
          layer => layer.toc && layer.toc.id === child.toc.id
        )
        layers.splice(index, 1)
      }
    })
  })

  draggableIndex = 1

  layers.forEach(layer => {
    if (layer.toc) {
      layer.toc.draggableIndex = draggableIndex
    } else {
      layer.draggableIndex = draggableIndex
    }
    draggableIndex++
  })
  return layers
}

const getLayers = (userLayers, masterCommonLayerList) => {
  let layers = []
  userLayers.forEach(layerObj => {
    if (layerObj in masterCommonLayerList) {
      layers.push(masterCommonLayerList[layerObj])
    } else {
      // console.log('layerObj :>>', layerObj)
      if (typeof layerObj === 'object') {
        if (layerObj.toc && layerObj.toc.type !== 'mam')
          layerObj.toc['userLayer'] = true
        layers.push(layerObj)
      } else {
        // invalid layer. Do nothing here.
      }
    }
  })

  return layers
}

const removeLayerFromStyle = (mapStyle, layerDeletedId) => {
  let style = mapStyle.toJS()
  // Note: there are issues with actually removing a layer from the style but not the layers source
  // we can not reliably remove both because there is a possibility the layer being deleted is a copy
  // and shares a source with other layers
  // a simple workaround is to set the layer being deleted visibility to none.
  style.layers.forEach(layer => {
    if (layer.id === layerDeletedId) {
      layer.layout.visibility = 'none'
    }
    if (layer.id === layerDeletedId + '_outline') {
      layer.layout.visibility = 'none'
    }
    if (layer.id === layerDeletedId + '_symbol') {
      layer.layout.visibility = 'none'
    }
  })
  return fromJS(style)
}

const addLayersToStyle = (mapStyle, dataConfig) => {
  // this function will check if there are any layers in the dataConfig but not in the style
  // if yes, the layers will be added to the mapStyle
  let style = mapStyle.toJS()
  const checkStyleForLayer = layerId => {
    const check = style.layers.filter(layer => layer.id === layerId)
    if (check.length) return true
    return false
  }
  const processTocLayer = layerItem => {
    if (layerItem.layersArray) {
      layerItem.layersArray.forEach(layer => {
        const inStyle = checkStyleForLayer(layer.layer.id)
        if (!inStyle) {
          layer.beforeLayer = 'country_label-other' // adds layer to bottom of layer stack
          style = addLayerToStyle(style, layer)
        }
      })
      layerItem.sourcesArray.forEach(source => {
        if (!style.sources[source.id]) {
          style = addSourceToStyle(style, source)
        }
      })
    } else if (layerItem.children && layerItem.children.items) {
      layerItem.children.items.forEach(childLayer => {
        processTocLayer(childLayer)
      })
    }
  }

  dataConfig.tocLayers.forEach(tocLayer => {
    processTocLayer(tocLayer)
  })

  return fromJS(style)
}

export default function MakeDataConfigComponent({
  onFinish,
  layerDeletedId,
  updateStyle = true,
  resetStyle = false,
}) {
  const dispatch = useDispatch()
  const { data: user } = useUser()
  const mapStyle = useSelector(state => state.mapStyle)
  const mapID = user?.mapID

  // Return early if user is not logged in or doesn't have a mapID
  if (!user?.isLoggedIn || !mapID) {
    onFinish && onFinish(null)
    return null
  }

  const [fetchObjects, setFetchObjects] = useState(null)
  const url = apis.apiDatabase.uri + `layers/common?mapID=${mapID}`
  const method = 'GET'

  apiFetch(url, method, null, result => {
    const masterCommonLayerList = result || {}

    const secondUrl = apis.apiDatabase.uri + `layers/user?mapID=${mapID}`
    apiFetch(secondUrl, method, null, result => {
      console.log('result MakeDataConfigComponent', result)
      let userLayers = []
      if (result.data && result.data.user && masterCommonLayerList)
        userLayers = build_userLayersArray(
          result.data.user,
          masterCommonLayerList
        )

      const imageryLayers = getImageryLayers(user)
      const auxiliaryLayers = [
        SELECTED_FEATURES,
        GEO_SPATIAL_RESULTS,
        CLICKED_FEATURE,
        DRAW,
        DELETE,
        MEASURE,
        MEASURE_CONTROL,
        COORDINATE_SEARCH,
        GEOCODE,
      ]
      const tocLayers = [...userLayers]
      const dataConfig = {
        tocLayers: tocLayers,
        commonLayers: masterCommonLayerList,
        auxiliaryLayers: auxiliaryLayers,
        imageryLayers: imageryLayers,
        layerHierarchy: result.data.layerHierarchy
          ? result.data.layerHierarchy
          : null,
      }
      console.log('DATA CONFIG ', dataConfig)
      if (utils.verifyResult(result)) {
        // TODO: this updateStyle can not be run after switching accounts
        // for some reason state does not have the mapStyle??
        // Figure this out.  For now, after switching accounts the style is updated onMapLoad when retruning back to map
        if (updateStyle) {
          if (layerDeletedId) {
            const newMapStyle = removeLayerFromStyle(mapStyle, layerDeletedId)
            dispatch(updateMapStyle(newMapStyle))
          } else {
            const newMapStyle = addLayersToStyle(mapStyle, dataConfig)
            dispatch(updateMapStyle(newMapStyle))
          }
        }
        if (resetStyle) {
          const newMapStyle = resetMapStyle(dataConfig)
          dispatch(updateMapStyle(newMapStyle))
        }
        dispatch(updateDataConfig(dataConfig))
        onFinish && onFinish(dataConfig)
      } else {
        onFinish && onFinish(null)
      }
    })
  })

  return null
}

export const resetMapStyle = dataConfig => {
  // this function will replace the current mapStyle with a new mapStyle starting from BASIC_STYLE
  // this is used when the map is switched with the mapSelector in MapSelector.js
  const dConfig = clone(dataConfig)
  let newMapStyle = clone(BASIC_STYLE)
  let visibleLayers = []
  newMapStyle = addTocLayersToMapStyle2(
    newMapStyle,
    null,
    dConfig,
    visibleLayers
  )
  dConfig['auxiliaryLayers'].forEach(config => {
    if (config.layersArray) {
      config.layersArray.forEach(layerObj => {
        let visible = layerObj.layer.layout.visibility
        newMapStyle = addLayerToStyle(newMapStyle, layerObj, visible)
      })
    }
    // add sources
    if (config.sourcesArray) {
      config.sourcesArray.forEach(
        source =>
          (newMapStyle = addSourceToStyle(
            newMapStyle,
            source,
            config.layersArray[0].source
          ))
      )
    }
  })
  dConfig['imageryLayers'].forEach(config => {
    if (config.layersArray) {
      config.layersArray.forEach(layerObj => {
        newMapStyle = addLayerToStyle(newMapStyle, layerObj)
      })
    }
    // add sources
    if (config.sourcesArray) {
      config.sourcesArray.forEach(
        source => (newMapStyle = addSourceToStyle(newMapStyle, source))
      )
    }
  })
  return fromJS(newMapStyle)
}

export const getImageryLayers = user => {
  let imagery = [GOOGLE]
  let activeAccount = user.accounts.filter(
    account => account.id === user.accountID
  )
  if (activeAccount[0].nearmap && activeAccount[0].nearmap !== '') {
    let nearMapClone = clone(NEARMAP)
    let nmURL = nearMapClone.sourcesArray[0].tiles[0]
    nmURL = `${nmURL}?apikey=${activeAccount[0].nearmap}`
    nearMapClone.sourcesArray[0].tiles[0] = nmURL
    imagery.push(nearMapClone)
  }
  return imagery
}

export const getOrderFromDataConfig = (dataConfig, deleteId = null) => {
  // create a order array to pass to the order ap1
  // Order is an array of objects following the below example
  /*
  [
    {layer: "layer_layer_8"},
    {layer: "layer_layer_2"},
    {
      group: "GgAkjLkgJk", 
      children: [{layer: "layer_layer_3"},{group: "LkPoKgAsFD"}],
    },
    {
      group: "LkPoKgAsFD", 
      children: [{layer: "layer_layer_5"}],
    }
  ]
  */
  const config = clone(dataConfig)
  const newOrder = []
  const processConfigChildren = configObj => {
    if (configObj.children) {
      if (deleteId) {
        // find index in groups array
        const groupsIndex = configObj.children.items.findIndex(
          group => group && group.groupId === deleteId
        )
        // find index in groupIds array
        const groupIdsIndex = configObj.children.groupIds.findIndex(
          groupId => groupId && groupId === deleteId
        )
        // remove group from groups array
        if (groupsIndex >= 0) {
          configObj.children.items.splice(groupsIndex, 1)
        }
        // remove groupId from groupIds array
        if (groupIdsIndex >= 0) {
          configObj.children.groupIds.splice(groupIdsIndex, 1)
        }
      }
      // configObj is group
      configObj.children.items.forEach(item => {
        if (item.children) {
          processConfigChildren(item)
        } else {
          newOrder.push({ layer: item.toc.id })
        }
      })

      let children = configObj.children.items.map(item => {
        if (item.children) {
          return { group: item.groupId }
        } else {
          return { layer: item.toc.id }
        }
      })

      newOrder.push({
        group: configObj.groupId,
        children: children,
      })
    } else {
      // configObj is layer
      newOrder.push({ layer: configObj.toc.id })
    }
  }
  config.forEach(configObj => {
    // if deleteId, the corresponding group is being deleted
    // return to remove the group from the order and cause deletion
    if (deleteId && configObj.groupId && configObj.groupId === deleteId) return
    processConfigChildren(configObj)
  })
  return newOrder
}

export const getLayerConfigUsingTocId = (dataConfig, layerId) => {
  let targetLayer = null
  const processLayer = layer => {
    if (layer.toc && layer.toc.id) {
      if (layer.toc.id === layerId) {
        targetLayer = layer
      }
    }
    if (layer.children) {
      layer.children.items.forEach(item => processLayer(item))
    }
  }

  dataConfig['tocLayers'].forEach(layer => {
    processLayer(layer)
  })

  return targetLayer
}

export const getCommonLayerConfigUsingLayerId = (dataConfig, layerId) => {
  let targetLayer = null
  const processLayer = layer => {
    if (layer.layersArray) {
      let targetLayerArray = layer.layersArray.filter(
        layerObj => layerObj.layer.id === layerId
      )
      if (targetLayerArray.length) targetLayer = layer
    }
    if (layer.children) {
      layer.children.items.forEach(item => processLayer(item))
    }
  }

  dataConfig['tocLayers'].forEach(layer => {
    processLayer(layer)
  })

  return targetLayer
}

export const getLayerConfigUsingLayerId = (dataConfig, layerId) => {
  let targetLayer = null
  const processLayer = layer => {
    if (layer.layersArray) {
      if (layer.layersArray[0].layer.id === layerId) {
        targetLayer = layer
      }
    }
    if (layer.children) {
      layer.children.items.forEach(item => processLayer(item))
    }
  }

  dataConfig['tocLayers'].forEach(layer => {
    processLayer(layer)
  })
  if (!targetLayer) {
    dataConfig['auxiliaryLayers'].forEach(layer => {
      processLayer(layer)
    })
  }

  return targetLayer
}

export const getVisibleLayersFromDataConfig = (tocLayers, style) => {
  let visibleLayers = []
  const processTocObj = (tocObj, styleLayer) => {
    if (tocObj.layersArray) {
      tocObj.layersArray.forEach(layerObject => {
        if (layerObject.layer.id === styleLayer.id) {
          visibleLayers.push(tocObj)
        }
      })
    }
    if (tocObj.children) {
      tocObj.children.items.forEach(item => {
        if (item.layersArray) {
          item.layersArray.forEach(layerObject => {
            if (layerObject.layer.id === styleLayer.id) {
              visibleLayers.push(item)
            }
          })
        } else {
          processTocObj(item, styleLayer)
        }
      })
    }
  }

  style.layers.forEach(styleLayer => {
    if (!styleLayer.layout) return
    if (styleLayer.layout.visibility === 'none') return
    tocLayers.forEach(tocObject => {
      processTocObj(tocObject, styleLayer)
    })
  })
  return visibleLayers
}

export const getAllLayersFromDataConfig = tocLayers => {
  let layers = []
  const processTocObj = tocObj => {
    if (tocObj.layersArray) {
      tocObj.layersArray.forEach(layerObject => {
        if (layerObject.layer.id) {
          layers.push(layerObject.layer)
        }
      })
    }
    if (tocObj.children) {
      tocObj.children.items.forEach(childObj => {
        if (childObj.children) {
          processTocObj(childObj)
        } else {
          if (childObj.layersArray) {
            childObj.layersArray.forEach(layerObject => {
              if (layerObject.layer.id) {
                layers.push(layerObject.layer)
              }
            })
          }
        }
      })
    }
  }

  tocLayers.forEach(tocObject => {
    processTocObj(tocObject)
  })

  return layers
}
