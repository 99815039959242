import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'

const LanguageContext = createContext()

// Provide a default value for the context to help with TypeScript and IDE support
LanguageContext.displayName = 'LanguageContext'

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language)

  // Handle initial language setup
  useEffect(() => {
    // Ensure we're using a supported language
    const storedLanguage = localStorage.getItem('i18nextLng')
    if (storedLanguage && storedLanguage !== currentLanguage) {
      // Only update if different to avoid unnecessary re-renders
      setCurrentLanguage(storedLanguage)
    }
  }, [])

  const changeLanguage = useCallback(
    language => {
      // Don't update if it's the same language to prevent unnecessary re-renders
      if (language === currentLanguage) return

      i18n.changeLanguage(language)
      setCurrentLanguage(language)
      localStorage.setItem('i18nextLng', language)
    },
    [i18n, currentLanguage]
  )

  // Use useMemo to prevent unnecessary re-renders when the provider re-renders
  // but the context value hasn't actually changed
  const contextValue = useMemo(
    () => ({
      currentLanguage,
      changeLanguage,
    }),
    [currentLanguage, changeLanguage]
  )

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  )
}

// Custom hook to use the language context
export const useLanguage = () => {
  const context = useContext(LanguageContext)

  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }

  return context
}
