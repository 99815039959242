import { useEffect, useCallback, useRef } from 'react'
import { FlyToInterpolator } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import { setViewPort } from '../../actions/index'

const ZoomToPoint = ({ coordinates, zoom = 14, onComplete }) => {
  const dispatch = useDispatch()
  const viewport = useSelector(state => state.viewport)
  const timeoutRef = useRef(null)
  const isTransitioning = useRef(false)
  const lastUpdate = useRef({ coordinates: null, zoom: null })

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      isTransitioning.current = false
    }
  }, [])

  const shouldUpdateViewport = useCallback(() => {
    if (!coordinates || !viewport) return false

    // If we haven't updated before, we should update
    if (!lastUpdate.current.coordinates) return true

    const [longitude, latitude] = coordinates
    const [lastLong, lastLat] = lastUpdate.current.coordinates

    // Check if the coordinates or zoom have changed significantly
    const coordsChanged =
      Math.abs(longitude - lastLong) > 0.0000001 ||
      Math.abs(latitude - lastLat) > 0.0000001
    const zoomChanged = Math.abs(zoom - lastUpdate.current.zoom) > 0.0000001

    return coordsChanged || zoomChanged
  }, [coordinates, zoom, viewport])

  const updateViewport = useCallback(() => {
    if (!viewport?.height || !viewport?.width || !coordinates) return

    const [longitude, latitude] = coordinates

    // Validate coordinates
    if (
      typeof longitude !== 'number' ||
      typeof latitude !== 'number' ||
      isNaN(longitude) ||
      isNaN(latitude)
    ) {
      console.warn('ZoomToPoint: Invalid coordinates:', coordinates)
      onComplete?.()
      return
    }

    // Don't update if we're already at the target
    if (!shouldUpdateViewport()) {
      onComplete?.()
      return
    }

    // Clear any pending timeouts
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    // If we're already transitioning, complete current transition immediately
    if (isTransitioning.current) {
      dispatch(
        setViewPort({
          ...viewport,
          transitionDuration: 0,
          latitude: viewport.latitude,
          longitude: viewport.longitude,
          zoom: viewport.zoom,
        })
      )
    }

    // Store the update we're about to make
    lastUpdate.current = {
      coordinates: coordinates,
      zoom: zoom,
    }

    // Start new transition
    isTransitioning.current = true
    dispatch(
      setViewPort({
        ...viewport,
        latitude,
        longitude,
        zoom,
        bearing: 0,
        pitch: 0,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
        onTransitionEnd: () => {
          isTransitioning.current = false
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = null
          }
          onComplete?.()
        },
      })
    )

    // Safety cleanup in case transition end doesn't fire
    timeoutRef.current = setTimeout(() => {
      if (isTransitioning.current) {
        isTransitioning.current = false
        onComplete?.()
      }
    }, 100)
  }, [viewport, coordinates, zoom, dispatch, onComplete, shouldUpdateViewport])

  useEffect(() => {
    updateViewport()
  }, [updateViewport])

  return null
}

export default ZoomToPoint
