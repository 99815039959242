import React, { createContext, useContext, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import PropTypes from 'prop-types'

import { updateDataConfig, updateMapStyle } from '@/actions'
import { apis } from '@/config/apiConfig'
import { DRAW } from '@/data/Layers/Auxiliary/Draw'
import { MEASURE } from '@/data/Layers/Auxiliary/Measure'
import { MEASURE_CONTROL } from '@/data/Layers/Auxiliary/MeasureControl'
import { DELETE } from '@/data/Layers/Auxiliary/Delete'
import { GEOCODE, COORDINATE_SEARCH } from '@/data/Layers/Auxiliary/Search'
import { CLICKED_FEATURE } from '@/data/Layers/Auxiliary/ClickedFeature'
import { SELECTED_FEATURES } from '@/data/Layers/Auxiliary/SelectedFeatures'
import { GEO_SPATIAL_RESULTS } from '@/data/Layers/Auxiliary/GeoSpatialResults'
import { apiFetch } from '@/utilities/api'
import { getImageryLayers } from '@/utilities/dataConfig'
import {
  addLayerToStyle,
  addSourceToStyle,
  addTocLayersToMapStyle2,
} from '@/utilities/mapStyle'

import { clone } from '@/utilities/geospatial'
import { BASIC_STYLE } from '@/mapStyles/basic/style'

import { unVal } from '@/utilities/util'
import { useUser } from '@/queries/hooks/use-user'

// Add the missing utility functions
const getLayers = (userLayers, masterCommonLayerList) => {
  const layers = []
  userLayers.forEach(layerObj => {
    if (layerObj in masterCommonLayerList) {
      layers.push(masterCommonLayerList[layerObj])
    } else if (typeof layerObj === 'object') {
      if (layerObj.toc && layerObj.toc.type !== 'mam') {
        layerObj.toc.userLayer = true
      }
      layers.push(layerObj)
    }
  })
  return layers
}

const processUserLayers = (userLayers, masterCommonLayerList) => {
  const layers = getLayers(userLayers, masterCommonLayerList)
  const groups = layers.filter(layer => layer.children)

  let draggableIndex = null
  groups.forEach(group => {
    draggableIndex = 1
    let newOrder = []
    const layerList = []
    group.children.groupIds.forEach(id => layerList.push(id))
    group.children.layerIds.forEach(id => layerList.push(id))

    if (group.children.order && group.children.order.length) {
      let unprocessedChildren = layerList
      if (layerList.length) {
        group.children.order.forEach(orderID => {
          if (layerList.includes(orderID)) {
            unprocessedChildren = unVal(unprocessedChildren, orderID)
            const viewObj = layers.filter(
              layer =>
                layer.id === orderID || (layer.toc && layer.toc.id === orderID)
            )
            if (viewObj[0] && viewObj[0].toc) {
              viewObj[0].toc.draggableIndex = draggableIndex
              draggableIndex += 1
            } else if (viewObj[0]) {
              viewObj[0].draggableIndex = draggableIndex
              draggableIndex += 1
            }
            newOrder.push(viewObj[0])
          }
        })
      }

      if (unprocessedChildren.length) {
        unprocessedChildren.forEach(unID => {
          const viewObj = layers.filter(
            layer => layer.id === unID || (layer.toc && layer.toc.id === unID)
          )
          if (viewObj[0] && viewObj[0].toc) {
            viewObj[0].toc.draggableIndex = draggableIndex
            draggableIndex += 1
          } else if (viewObj[0]) {
            viewObj[0].draggableIndex = draggableIndex
            draggableIndex += 1
          }
          newOrder.push(viewObj[0])
        })
      }
    } else {
      layerList.forEach(layerID => {
        const viewObj = layers.filter(
          layer =>
            layer.id === layerID || (layer.toc && layer.toc.id === layerID)
        )
        if (viewObj[0] && viewObj[0].toc) {
          viewObj[0].toc.draggableIndex = draggableIndex
          draggableIndex += 1
        } else if (viewObj[0]) {
          viewObj[0].draggableIndex = draggableIndex
          draggableIndex += 1
        }
        newOrder.push(viewObj[0])
      })
    }
    newOrder = newOrder.filter(el => el != null)
    group.children.items = newOrder

    group.children.items.forEach(child => {
      if (child.children) {
        const index = layers.findIndex(
          layer => layer.groupId && layer.groupId === child.groupId
        )
        layers.splice(index, 1)
      } else if (child.toc.id) {
        const index = layers.findIndex(
          layer => layer.toc && layer.toc.id === child.toc.id
        )
        layers.splice(index, 1)
      }
    })
  })

  draggableIndex = 1
  layers.forEach(layer => {
    if (layer.toc) {
      layer.toc.draggableIndex = draggableIndex
    } else {
      layer.draggableIndex = draggableIndex
    }
    draggableIndex += 1
  })
  return layers
}

const buildUserLayersArray = (userLayers, masterCommonLayerList) => {
  if (typeof userLayers !== 'object') {
    return Object.values(masterCommonLayerList)
  }
  const layers = processUserLayers(userLayers, masterCommonLayerList)
  return layers
}

const removeLayerFromStyle = (mapStyle, layerDeletedId) => {
  const style = mapStyle.toJS()
  style.layers.forEach(layer => {
    if (layer.id === layerDeletedId) {
      layer.layout.visibility = 'none'
    }
    if (layer.id === layerDeletedId + '_outline') {
      layer.layout.visibility = 'none'
    }
    if (layer.id === layerDeletedId + '_symbol') {
      layer.layout.visibility = 'none'
    }
  })
  return fromJS(style)
}

const addLayersToStyle = (mapStyle, dataConfig) => {
  let style = mapStyle.toJS()
  const checkStyleForLayer = layerId => {
    const check = style.layers.filter(layer => layer.id === layerId)
    return check.length > 0
  }

  const processTocLayer = layerItem => {
    if (layerItem.layersArray) {
      layerItem.layersArray.forEach(layer => {
        const inStyle = checkStyleForLayer(layer.layer.id)
        if (!inStyle) {
          layer.beforeLayer = 'country_label-other'
          style = addLayerToStyle(style, layer)
        }
      })
      layerItem.sourcesArray.forEach(source => {
        if (!style.sources[source.id]) {
          style = addSourceToStyle(style, source)
        }
      })
    } else if (layerItem.children && layerItem.children.items) {
      layerItem.children.items.forEach(childLayer => {
        processTocLayer(childLayer)
      })
    }
  }

  dataConfig.tocLayers.forEach(tocLayer => {
    processTocLayer(tocLayer)
  })

  return fromJS(style)
}

export const resetMapStyle = dataConfig => {
  // this function will replace the current mapStyle with a new mapStyle starting from BASIC_STYLE
  // this is used when the map is switched with the mapSelector in MapSelector.js
  const dConfig = clone(dataConfig)
  let newMapStyle = clone(BASIC_STYLE)
  const visibleLayers = []
  newMapStyle = addTocLayersToMapStyle2(
    newMapStyle,
    null,
    dConfig,
    visibleLayers
  )
  dConfig.auxiliaryLayers.forEach(config => {
    if (config.layersArray) {
      config.layersArray.forEach(layerObj => {
        const visible = layerObj.layer.layout.visibility
        newMapStyle = addLayerToStyle(newMapStyle, layerObj, visible)
      })
    }
    // add sources
    if (config.sourcesArray) {
      config.sourcesArray.forEach(source => {
        newMapStyle = addSourceToStyle(
          newMapStyle,
          source,
          config.layersArray[0].source
        )
      })
    }
  })
  dConfig.imageryLayers.forEach(config => {
    if (config.layersArray) {
      config.layersArray.forEach(layerObj => {
        newMapStyle = addLayerToStyle(newMapStyle, layerObj)
      })
    }
    // add sources
    if (config.sourcesArray) {
      config.sourcesArray.forEach(source => {
        newMapStyle = addSourceToStyle(newMapStyle, source)
      })
    }
  })
  return fromJS(newMapStyle)
}

const DataConfigContext = createContext()

export function DataConfigProvider({ children }) {
  const dispatch = useDispatch()
  const { data: user, refetch: refetchUser } = useUser()
  const mapStyle = useSelector(state => state.mapStyle)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const loadDataConfig = useCallback(
    async ({
      layerDeletedId,
      updateStyle = true,
      resetStyle = false,
      refetchUser: refetchUserParam = false,
    }) => {
      setIsLoading(true)
      setError(null)

      let dataConfigUser = user
      if (refetchUserParam) {
        const { data: updatedUser } = await refetchUser()
        dataConfigUser = updatedUser
      }

      return new Promise((resolve, reject) => {
        Promise.resolve().then(async () => {
          try {
            // First API call to get master common layer list
            const masterCommonLayerListPromise = new Promise((res, rej) => {
              const url = `${apis.apiDatabase.uri}layers/common?mapID=${dataConfigUser.mapID}`
              console.log('masterCommonLayerList', url)
              apiFetch(url, 'GET', null, result => {
                res(result || {})
              })
            })

            // Second API call to get user layers
            const userLayersResultPromise = new Promise((res, rej) => {
              const url = `${apis.apiDatabase.uri}layers/user?mapID=${dataConfigUser.mapID}`
              console.log('userLayersResult', url)
              apiFetch(url, 'GET', null, res)
            })

            const [masterCommonLayerList, userLayersResult] = await Promise.all(
              [masterCommonLayerListPromise, userLayersResultPromise]
            )

            let userLayers = []
            if (userLayersResult.data?.user && masterCommonLayerList) {
              userLayers = buildUserLayersArray(
                userLayersResult.data.user,
                masterCommonLayerList
              )
            }

            const imageryLayers = getImageryLayers(dataConfigUser)
            const auxiliaryLayers = [
              SELECTED_FEATURES,
              GEO_SPATIAL_RESULTS,
              CLICKED_FEATURE,
              DRAW,
              DELETE,
              MEASURE,
              MEASURE_CONTROL,
              COORDINATE_SEARCH,
              GEOCODE,
            ]

            const dataConfig = {
              tocLayers: [...userLayers],
              commonLayers: masterCommonLayerList,
              auxiliaryLayers,
              imageryLayers,
              layerHierarchy: userLayersResult.data.layerHierarchy || null,
            }

            if (updateStyle) {
              if (layerDeletedId) {
                const newMapStyle = removeLayerFromStyle(
                  mapStyle,
                  layerDeletedId
                )
                dispatch(updateMapStyle(newMapStyle))
              } else {
                const newMapStyle = addLayersToStyle(mapStyle, dataConfig)
                dispatch(updateMapStyle(newMapStyle))
              }
            }
            if (resetStyle) {
              const newMapStyle = resetMapStyle(dataConfig)
              dispatch(updateMapStyle(newMapStyle))
            }

            dispatch(updateDataConfig(dataConfig))
            const result = { dataConfig, user: dataConfigUser }
            resolve(result)
            setIsLoading(false)
            console.log('loadDataConfig finished', dataConfigUser.accountID)
          } catch (err) {
            setError(err)
            setIsLoading(false)
            reject(err)
          }
        })
      })
    },
    [dispatch, mapStyle, refetchUser, user]
  )

  const value = {
    isLoading,
    error,
    loadDataConfig,
  }

  return (
    <DataConfigContext.Provider value={value}>
      {children}
    </DataConfigContext.Provider>
  )
}

DataConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useDataConfig() {
  const context = useContext(DataConfigContext)
  if (context === undefined) {
    throw new Error('useDataConfig must be used within a DataConfigProvider')
  }
  return context
}
